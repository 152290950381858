@charset "UTF-8";
@import url("https://fonts.cdnfonts.com/css/open-dyslexic");
h1, h2, h3, h4, h5, ul {
  color: #000 !important;
}

p, span {
  color: black;
}

li {
  color: #000;
}

.titulo {
  margin-top: 3%;
}
@media (max-width: 768px) {
  .titulo {
    margin-top: 25%;
  }
}

.titulo h1 {
  text-shadow: 2px 2px 2px rgba(155, 153, 153, 0.8);
}

p {
  font-family: "Open-Dyslexic", sans-serif !important;
  font-size: 15px;
}

h1, h2, h3, h4, h5 {
  font-family: "Poppins", sans-serif !important;
}

.blog-card {
  box-sizing: border-box;
  width: inherit;
  overflow: hidden;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  background-color: rgba(135, 154, 166, 0.5);
}
.blog-card img {
  width: 100%;
  height: auto;
}

.fondo-transparente-card {
  margin: 2%;
}

.blog-card {
  width: auto;
}

.underline {
  text-decoration-line: underline;
}

hr {
  margin-left: 25% !important;
  margin-right: 25% !important;
}

hr.style-seven {
  overflow: visible;
  /* For IE */
  padding: 0;
  border: none;
  border-top: medium double #333;
  color: #333;
  text-align: center;
  width: 50%;
}

hr.style-seven:after {
  content: "§";
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  padding: 0 0.25em;
  background: white;
}

.contenido {
  background-image: url("assets/img/wave-body1.jpg");
  background-position: center center;
  background-size: cover;
}

#input {
  color: black !important;
}